import React, { Component } from "react";
import SocialMediaLink from "./SocialMediaLink";

class Footer extends Component {

    getYearNotice(){
        const startYear = 2018;
        const curYear = new Date().getFullYear();
        return startYear !== curYear ? startYear + ' - ' + curYear : curYear;
    }

    render() {
        const iconSize = "1x";
        const socials =  [
            {
                class: "facebook",
                icon: "facebook-f",
                url: "https://www.facebook.com/OmkaraHellcore"
            },
            {
                class: "instagram",
                icon: "instagram",
                url: "https://www.instagram.com/omkarahellcore"
            },
            {
                class: "youtube",
                icon: "youtube",
                url: "https://www.youtube.com/channel/UCWH96UPL2g5cY5vaByqshAA"
            },
            {
                class: "soundcloud",
                icon: "soundcloud",
                url: "https://soundcloud.com/omkarahellcore"
            },
            {
                class: "spotify",
                icon: "spotify",
                url: "https://open.spotify.com/artist/45wD0Yd3jwJu3mfsDQXVA0?si=l3YtJlFAR1uKsLQwqmH4HQ"
            }
        ];
        const links = socials.map((link) =>
            <SocialMediaLink url={link.url} class={link.class} icon={link.icon} size={iconSize} key={link.class} />
        );        
        return (
            
            <footer className="footer">
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="float-left">
                                © {this.getYearNotice()} Omkara Hellcore
                            </div>
                        </div>
                        <div className="col-6">
                            <div className="float-right">
                                {links}
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;