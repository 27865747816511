import React, { Component } from "react";

export default class Music extends Component {
  constructor(props){
    super(props);
    this.state = {
      scheme: props.scheme
    }
  }
  render() {
    const spotifyEmbed = {
      width: "100%",
      height: "270",
      url: "https://open.spotify.com/embed/user/46elm5tc36evdis0d6kalkez4/playlist/0ExvCWd1CSAnhCpIegFsO7"
    }
    const soundcloudEmbed = {
      playlistId: 742480011
    };

    return (
      <section id="Music" className={this.state.scheme}>
        <div className="container">
          <h3 className="text-center">Music</h3>
          <iframe title="spotify" width={spotifyEmbed.width} height={spotifyEmbed.height} scrolling="yes" frameBorder="0" allowtransparency="true" allow="encrypted-media" src={spotifyEmbed.url}></iframe>
          <hr/>
          <iframe title="soundcloud" width="100%" height="400" scrolling="yes" frameBorder="no" allow="autoplay" src={"https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/" + soundcloudEmbed.playlistId + "&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"}></iframe>
        </div>
      </section>
    );
  }
}