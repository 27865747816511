import React, { Component} from "react";
import {
    isMobile
  } from "react-device-detect";
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem } from 'reactstrap';
import Scrollchor from 'react-scrollchor'; 
import logo from "../img/logo.png";

export default class NavBar extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.collapse = this.collapse.bind(this);

        this.state = {
            isOpen: false
        };
    }
    toggle(){
        this.setState({
            isOpen: !this.state.isOpen
        });
    }
    collapse(){
        if (this.state.isOpen){
            this.toggle();
        }
    }
    render() {
        let offset = isMobile ? -290 : -85;
        return (
            <Navbar expand="md" dark sticky="top">
                <div className="container">
                    <Scrollchor to="#Home" className="navbar-brand">
                        <img src={logo} alt="Omkara Hellcore"/>
                    </Scrollchor>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem>
                                <Scrollchor to="#Media" animate={{offset: offset}} beforeAnimate={this.collapse} className="nav-link">Media</Scrollchor>
                            </NavItem>                        
                            <NavItem>
                                <Scrollchor to="#Music" animate={{offset: offset}} beforeAnimate={this.collapse} className="nav-link">Music</Scrollchor>
                            </NavItem>
                            <NavItem>
                                <Scrollchor to="#Events" animate={{offset: offset}} beforeAnimate={this.collapse} className="nav-link">Events</Scrollchor>
                            </NavItem>
                            <NavItem>
                                <Scrollchor to="#Merchandise" animate={{offset: offset}} beforeAnimate={this.collapse} className="nav-link">Merchandise</Scrollchor>
                            </NavItem>
                            <NavItem>
                                <Scrollchor to="#Patreon" animate={{offset: offset}} beforeAnimate={this.collapse} className="nav-link">Patreon</Scrollchor>
                            </NavItem>
                            <NavItem>
                                <Scrollchor to="#Contact" animate={{offset: offset}} beforeAnimate={this.collapse} className="nav-link">Contact</Scrollchor>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </div>
            </Navbar>
        );
    }
}