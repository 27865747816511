import React, { Component } from "react";
import patreonBtn from "../img/become_a_patron_button.png";

export default class Patreon extends Component {
  constructor(props){
    super(props);
    this.state = {
      scheme: props.scheme
    }
  }
  render() {

    return (
      <section id="Patreon" className={this.state.scheme}>
        <div className="container">
          <h3 className="text-center">Patreon</h3>
          <div className="col-sm-12 col-md-8 offset-md-2">
            <div className="patreon-text">
              <p>Like many creative people, I'm at my best when I'm building on my work. Creativity isn't just a career objective for me. It's what I would be doing even if it weren't a job. Any artist like me reading this knows exactly what I mean, because they probably feel the same way.</p>
              <p>The Internet has not only given me a worldwide audience for my music, it has also introduced me to a community of generous and motivated people who want to see artists and musicians succeed. The tools that have been made available to me are humbling, because I know how powerful they are and what other artists have accomplished with them.</p>
              <p>The people I've met through my music all believe their lives are made better by artists' works, and as the beneficiary of their kind words and contributions, musicians like myself know our lives are made better as well. This is the world we all dreamed of when we became artists in the first place.</p>
              <p>I plan to invest many hours in my music, and that is why I am committing myself and my studio to this crowdfunding campaign and the fans that make it possible. As patrons, you will all receive everything I produce, including VIP discounts on any merchandise designs I introduce.</p>
              <p>I know music is as important to you as it is to me. I would be honored if you would consider joining our community. Become a patron and help me achieve the musical excellence I've been building towards since the beginning. I think you'll agree the extra effort and time will be well spent. Thanks for your time and your generous contributions.</p>
            </div>
            <div className="patreon-link">
              <a href="https://www.patreon.com/omkarahellcore" target="_blank" rel="noopener noreferrer">
                  <img src={patreonBtn} alt="" style={{width: '175px'}}/>
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }
}