import React, { Component } from "react";
import { FacebookProvider, Page } from "react-facebook";

export default class Events extends Component {
    constructor(props){
        super(props);
        this.state = {
            scheme: props.scheme
        }
    }

    render() {
        return (
            <section id="Events" className={this.state.scheme}>
                <div className="container">
                    <h3 className="text-center">Events</h3>
                    <FacebookProvider appId="313476819271298">
                        <Page href="https://www.facebook.com/pg/OmkaraHellcore/"
                            tabs="events" 
                            smallHeader="true"
                        />
                    </FacebookProvider>
                </div>
            </section>
        );
    }
}