import React, { Component } from "react";
import Instafeed from "react-instafeed";

export default class Media extends Component {
    constructor(props){
        super(props);
        this.state = {
            scheme: props.scheme
        }
    }

    render() {
        let instafeedConf = {
            target: 'Instafeed',
            userId: '215418633',
            accessToken: '215418633.1677ed0.0f3447d5cb6b4235ae9e08bf48ab0ee6'
        }
        return (
            <section id="Media" className={this.state.scheme}>
                <div className="container">
                    <h3 className="text-center">Media</h3>
                    {/* <div id={instafeedConf.target} className="instafeed-container">
                        <Instafeed
                            limit='20'
                            ref='instafeed'
                            resolution='standard_resolution'
                            sortBy='most-recent'
                            target={instafeedConf.target}
                            template="<div class='col-6 col-sm-4 col-md-3'>
                                        <div class='row instafeed-item'>
                                            <div class='instafeed-image'>
                                                <a href='{{link}}' target='_blank' class='instafeed-link'>
                                                    <img class='img-fluid' src='{{image}}' />
                                                </a>
                                            </div>
                                        </div>
                                    </div>"
                            userId={instafeedConf.userId}
                            accessToken={instafeedConf.accessToken}
                        />
                    </div> */}
                    <div class='embed-container'><iframe src='https://www.youtube.com/embed/l44yVeim-ws' frameborder='0' allowfullscreen></iframe></div>
                </div>
          </section>
        );
    }
}