import React, { Component } from "react";
import heroHeader from "../img/hero_header.jpg";

export default class Hero extends Component {
    render() {
        return (
            <section id="Hero" className="hero-scheme">
                <div className="text-center">
                    <img src={heroHeader} className="img-fluid" alt=""/>
                </div>
            </section>
        );
    }
}