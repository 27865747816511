import React, { Component } from "react";

import Navigation from "./Navigation";
import SocialMediaBar from "./SocialMediaBar";

import Music from "./Music";
import Footer from "./Footer";
import Hero from "./Hero";
import Merchandise from "./Merchandise";
import Events from "./Events";
import Media from  "./Media";
import Patreon from "./Patreon";
import Contact from "./Contact";
import Mastering from "./Mastering";

export default class Main extends Component {
  render() {
    return (
      <div id="Home" className="root-container">
        <Navigation />
        <Hero />
        <SocialMediaBar/>
        <main>
          <Media scheme="dark"/>
          <Music scheme="light"/>
          <Events scheme="dark"/>
          <Merchandise scheme="light"/>
          <Patreon scheme="dark" />
          <Mastering scheme="light"/>
          <Contact scheme="dark"/>
        </main>
        <Footer/>
      </div>
    );
  }
}