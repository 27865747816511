import React, { Component } from "react";
import SocialMediaLink from "./SocialMediaLink";

export default class SocialMediaBar extends Component {
    render() {
        const iconSize = "2x";
        const socials =  [
            {
                class: "facebook",
                icon: "facebook-f",
                url: "https://www.facebook.com/OmkaraHellcore"
            },
            {
                class: "instagram",
                icon: "instagram",
                url: "https://www.instagram.com/omkarahellcore"
            },
            {
                class: "youtube",
                icon: "youtube",
                url: "https://www.youtube.com/channel/UCWH96UPL2g5cY5vaByqshAA"
            },
            {
                class: "soundcloud",
                icon: "soundcloud",
                url: "https://soundcloud.com/omkarahellcore"
            },
            {
                class: "spotify",
                icon: "spotify",
                url: "https://open.spotify.com/artist/45wD0Yd3jwJu3mfsDQXVA0?si=l3YtJlFAR1uKsLQwqmH4HQ"
            }
        ];
        const links = socials.map((link) =>
            <SocialMediaLink url={link.url} class={link.class} icon={link.icon} size={iconSize} key={link.class} />
        );

        return (
            <section id="SocialMedia">
                <div className="social-media-bar">
                    <div className="container d-flex justify-content-center">
                        {links}
                    </div>
                </div>
            </section>
        );
    }
}