import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class SocialMediaLink extends Component {
    render() {
        return (
            <a className={'social-link social-' + this.props.class} href={this.props.url}  target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={['fab', this.props.icon]} size={this.props.size}/>
            </a>
        );
    }
}