import React, { Component } from "react";
import { Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Mastering extends Component {
    constructor(props){
        super(props);
        this.state = {
            scheme: props.scheme
        }
    }

    render(){
        return (
            <section id="Mastering" className={this.state.scheme}>
                <div className="pricing-header px-3 pb-3 mx-auto text-center">
                    <h3>Mastering Services</h3>
                    <p className="lead">
                        Aside from booking me as a performer, I also provide mastering services for a fee of €25 per song.
                        {/* Below you will find a table with the cost for the services dependant on the number of tracks to master. */}
                    </p>
                </div>
                {/* <div className="container">
                    <div className="card-deck text-center">
                        <div className="card mb-3 box-shadow">
                            <div className="card-header">
                                <h4 className="my-0 font-weight-normal">1 Song</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">€25 <small className="text-muted">/ song</small></h1>
                            </div>
                        </div>
                        <div className="card mb-3 box-shadow">
                            <div className="card-header">
                                <h4 className="my-0 font-weight-normal">2 Songs</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">€20 <small className="text-muted">/ song</small></h1>
                            </div>
                        </div>
                        <div className="card mb-3 box-shadow">
                            <div className="card-header">
                                <h4 className="my-0 font-weight-normal">3-6 Songs</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">€15 <small className="text-muted">/ song</small></h1>
                            </div>
                        </div>
                        <div className="card mb-3 box-shadow">
                            <div className="card-header">
                                <h4 className="my-0 font-weight-normal">7+ Songs</h4>
                            </div>
                            <div className="card-body">
                                <h1 className="card-title pricing-card-title">€10 <small className="text-muted">/ song</small></h1>
                            </div>
                        </div>
                    </div>
                </div>     */}
                <div className="container text-center">
                    <p className="lead">
                        On top of these services, I also provide a mixdown option at a fee of €5 per stem / track.
                    </p>
                    <div className="row justify-content-center">
                        <Button
                            href="mailto:info@omkarahellcore.com?subject=Mastering Services"
                            color="primary"
                            size="sm"
                            className="merch-btn float-left">
                            <FontAwesomeIcon icon="envelope"/> Email us
                        </Button>
                    </div>
                </div>
            </section>
        );
    }
}