import React, { Component } from "react";
import { Button, ButtonGroup } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default class Contact extends Component {
    constructor(props){
        super(props);
        this.state = {
            scheme: props.scheme
        }
    }

    render() {
        let bookingConf = {
            url: "http://www.bkjnbookings.com/catalog/manufacturer/manufacturer_page?book=true&manufacturer_id=52",
            email: "info@bkjnbookings.com",
            subject: "Booking Omkara"
        }
        
        return (
            <section id="Contact" className={this.state.scheme}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 text-center">
                            <h3>Bookings</h3>
                        </div>
                        <div className="col-12 text-center">
                            <ButtonGroup>
                                <Button
                                    href={bookingConf.url}
                                    color="danger"
                                    target="_blank">
                                    <FontAwesomeIcon icon="comment-alt"/> Book now
                                </Button>
                                <Button
                                    href={"mailto:" + bookingConf.email + "?subject=" + bookingConf.subject}
                                    color="primary">
                                    <FontAwesomeIcon icon="envelope"/> Email BKJN
                                </Button>
                            </ButtonGroup>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}