import React, { Component } from "react";
import Numeral from "numeral";
import {
    BrowserView,
    MobileView
  } from "react-device-detect";
export default class MerchItem extends Component {
    constructor(props){
        super(props);
        
        this.toggle = this.toggle.bind(this);
        
        this.props = props;
        this.state = {
            toggled: false
        }
    }
    
    toggle (){
        this.setState({
            toggled: !this.state.toggled
        });
    }

    render() {
        return(
            <div className="col-6 col-sm-6 col-md-4 col-lg-3">
                <div className="merch-item">
                    <div className="merch-image">
                    <BrowserView>
                        <img className="img-fluid"
                            onMouseEnter={e => e.currentTarget.src = this.props.back}
                            onMouseLeave={e => e.currentTarget.src = this.props.front}
                            src={this.props.front}
                            alt={this.props.name} />
                    </BrowserView>
                    <MobileView>
                        <img className="img-fluid"
                            style={{cursor: 'pointer'}}
                            onClick={this.toggle.bind(this)}
                            src={this.state.toggled ? this.props.back : this.props.front}
                            alt={this.props.name} />
                    </MobileView>
                    </div>
                    <div className="merch-name">{this.props.name}</div>
                    <div className="merch-cost">€{Numeral(this.props.cost).format("0.00")}</div>
                </div>
            </div>
        );
    }
}