import "./styles/index.scss";

import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App";

import './styles/fonts.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faCommentAlt } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faEnvelope, faCommentAlt)

ReactDOM.render(
  <App/>, 
  document.getElementById("root")
);