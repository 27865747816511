import React, { Component } from "react";
import MerchItem from "./MerchItem";
import { FacebookProvider, MessageUs } from 'react-facebook';
import { Button } from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import spreadShirtLogo from "../img/spreadshirt_logo.png";

var images = require.context('../img/merch/', true);

export default class Merchandise extends Component {
    constructor(props){
        super(props);
                
        this.state = {
            scheme: props.scheme
        }
    }

    // createMerch() {
    //     let merchObjects = [
    //         { id: 1, name: 'hoodie', cost: 45, front: images('./hoodie_front.jpg'), back: images('./hoodie_back.jpg')},
    //         { id: 2, name: 'shirt', cost: 20, front: images('./tshirt_front.jpg'), back: images('./tshirt_back.jpg')},
    //         //{ id: 3, name: 'spaghetti top', cost: 25, front: images('./tanktop_f_front.jpg'), back: images('./tanktop_f_back.jpg')},
    //         { id: 4, name: 'tank top', cost: 20, front: images('./tanktop_m_front.jpg'), back: images('./tanktop_m_back.jpg')},
    //         { id: 5, name: 'sports bra', cost: 20, front: images('./sportsbra_front.jpg'), back: images('./sportsbra_front.jpg')},
    //         // { id: 6, name: 'flag', cost: 30, front: 'https://placekitten.com/565/565', back: 'https://placekitten.com/565/565'},
    //         // { id: 7, name: 'cap', cost: 20, front: 'https://placekitten.com/250/250', back: 'https://placekitten.com/250/250'},
    //         // { id: 8, name: 'pet cat', cost: 100, front: 'https://placekitten.com/300/300', back: 'https://placekitten.com/300/300'},
    //     ];
    //     let merchElements = merchObjects.map((merch) =>
    //         <MerchItem key={merch.id} name={merch.name} cost={merch.cost} front={merch.front} back={merch.back}/>
    //     );
    //     return merchElements;
    // }

    render() {
        return (
            <section id="Merchandise" className={this.state.scheme}>
                <div className="container">
                    <h3 className="text-center">Merchandise</h3>
                    <p className="text-center"> 
                        <p>Please visit the official Omkara Hellcore Spreadshirt Shop for the latest merch.</p>
                        <a href="https://shop.spreadshirt.nl/omkarahellcore/" target="_blank" rel="noopener noreferrer" >
                            <img src={spreadShirtLogo} alt="" style={{width: '175px'}}/>
                            <br/><br/>
                            <div className="btn btn-sm btn-dark">Go to Shop</div>
                        </a>
                    </p>
                    {/* <div className="merch-container row">
                        {this.createMerch()}
                    </div>
                    <div className="merch-order text-center py-4">
                        <div className="row justify-content-center">
                            <div className="col-12 pb-3">
                                To order, contact us below:
                            </div>
                            <div className="col-8 col-sm-6 col-md-4 col-lg-3 merch-btn-col clearfix">
                                <Button
                                    href="mailto:info@omkarahellcore.com?subject=Merchandise Order"
                                    color="primary"
                                    size="sm"
                                    className="merch-btn float-left">
                                    <FontAwesomeIcon icon="envelope"/> Email Us
                                </Button>
                                <FacebookProvider appId="313476819271298">
                                    <MessageUs messengerAppId="313476819271298" pageId="235005016571456"/>
                                </FacebookProvider>
                            </div>
                        </div>
                    </div>
                    <div className="merch-disclaimer text-center">
                        Prices shown are including taxes, but excluding shipping fees.<br/>
                        Depending on the destination country, an additional shipping fee of €6.50 (up to a maximum €24.00) will be charged.
                    </div> */}
                </div>
            </section>
        );
    }
}